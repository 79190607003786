//本地导入
import request from "../request";

//客服列表
export const customerList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/list",
  });

//保存客服
export const saveCustomer = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/save",
  });

//删除客服
export const removeCustomer = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/remove",
  });

//客服标签列表
export const customerTagList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/tag/list",
  });

//保存客服标签
export const saveCustomerTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/tag/save",
  });

//删除客服标签
export const removeCustomerTag = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/tag/remove",
  });

//客服标签用户列表
export const customerTagUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/tag/user/list",
  });

//保存客服标签用户
export const saveCustomerTagUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/tag/user/save",
  });

//删除客服标签用户
export const removeCustomerTagUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/customer/tag/user/remove",
  });
