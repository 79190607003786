
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { countryNoComList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { customerList, removeCustomer, customerTagList, saveCustomer } from "@/api/request/customer";

//组件
@Component({
  name: "CustomerList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private customerTagList: any[] = [];
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //临时数据
    tag_id_str: "", //标签ID
    user_id_str: "", //用户ID

    //请求数据
    title: "", //名称
    tag_id: 0, //标签ID
    user_id: 0, //用户ID
    country: "", //归属国家
    tag_name: "", //标签名称
    nick_name: "", //客服用户昵称
  };

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取标签列表
    this.getCustomerTagList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.tag_id = Number(this.listQuery.tag_id_str);
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await customerList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      user_id_str: "", //客服用户ID

      //请求数据
      id: 0, // 客服ID
      title: "", // 客服名称
      tag_id: 0, // 客服标签ID
      user_id: 0, // 客服用户ID
      country: "", // 归属国家，必需
    };

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "create";
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //获取标签列表
  private async getCustomerTagList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await customerTagList({ page: 1, page_size: GFunc.getListRowLimit() });

    //数据赋值
    this.customerTagList = data.list;
    this.customerTagList.unshift({ id: 0, name: "请选择标签" });
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.addForm = {
      //临时数据
      user_id_str: String(row.user_id), //客服用户ID

      //请求数据
      id: row.id, // 客服ID
      title: row.title, // 客服名称
      tag_id: row.tag_id, // 客服标签ID
      user_id: row.user_id, // 客服用户ID
      country: row.country, // 归属国家，必需
    };

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "update";
  }

  //处理删除
  private handleDelete(row: any): void {
    //删除消息
    this.$confirm("确定删除客服 ?", "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //删除博主
        await removeCustomer({ id: row.id });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //---------------------------- 编辑/创建客服 ----------------------------
  //定义变量
  private dialogStatus: string = ""; //对话框状态
  private dialogVisible: boolean = false; //是否显示
  private dialogLoading: boolean = false; //对话框等待
  private dialogTitleMap: any = { update: "编辑客服", create: "新增客服" }; //对话框标题

  //表单数据
  private addForm: any = {
    //临时数据
    user_id_str: "", //客服用户ID

    //请求数据
    id: 0, // 客服ID
    title: "", // 客服名称
    tag_id: 0, // 客服标签ID
    user_id: 0, // 客服用户ID
    country: "", // 归属国家，必需
  };

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //处理创建
  private async createData() {
    //异常处理
    if (this.addForm.title == "") return Message({ type: "error", duration: 5 * 1000, message: "请输入客服名称" });
    if (this.addForm.country == "") return Message({ type: "error", duration: 5 * 1000, message: "请选择归属国家" });
    if (Number(this.addForm.user_id_str) == 0) return Message({ type: "error", duration: 5 * 1000, message: "请输入客服用户ID" });

    //数据赋值
    this.addForm.user_id = Number(this.addForm.user_id_str);

    //保存数据
    await saveCustomer(this.addForm);

    //显示提示
    this.$notify({ title: "成功", message: "创建成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }

  //处理更新
  private async updateData() {
    //异常处理
    if (this.addForm.title == "") return Message({ type: "error", duration: 5 * 1000, message: "请输入客服名称" });
    if (this.addForm.country == "") return Message({ type: "error", duration: 5 * 1000, message: "请选择归属国家" });
    if (Number(this.addForm.user_id_str) == 0) return Message({ type: "error", duration: 5 * 1000, message: "请输入客服用户ID" });

    //数据赋值
    this.addForm.user_id = Number(this.addForm.user_id_str);

    //保存数据
    await saveCustomer(this.addForm);

    //显示提示
    this.$notify({ title: "成功", message: "更新成功", type: "success", duration: 2000 });

    //隐藏界面
    this.dialogVisible = false;

    //重新获取数据
    this.getList();
  }
}
